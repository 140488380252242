.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: fixed;
  top: 0px;
  z-index: 1;
  
 
}

.card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
  max-width: 1200px; /* adjust as needed */
  margin: 0 auto;
}

.card h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.card p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 0;
}

.header img{
  height:30px;
}

/* Overlay Style */
/* Overlay Style */
.asideoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Increase blur effect for depth */
  z-index: 2; /* Place the overlay behind the aside */
  display: none; /* Initially hidden */
  transition: opacity 0.3s ease; /* Add a transition for smooth fading */
}

/* Show the overlay when the aside is open */
.asideoverlay.open {
  display: block;
}

.aside .card {
  transition: transform 0.3s ease-in-out; /* Add smooth transition for the transform property */
}

/* Apply the hover effect to expand the card */
.aside .card:hover {
  transform: scale(1.05); /* Scale up the card size slightly */
  color: #0f6;
  /* -webkit-text-fill-color: transparent; */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}
.sidemodal .menu {
  transition: transform 0.3s ease-in-out; /* Add smooth transition for the transform property */
}

/* Apply the hover effect to expand the card */
.sidemodal .menu:hover {
  transform: scale(1.05); /* Scale up the card size slightly */
  font-weight: bolder;
  /* color: #0f6; */
  /* -webkit-text-fill-color: transparent; */
  /* text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); */
}


/* Aside Style */
/* Define disco lights animation */
@keyframes discoLights {
  0% {
    border-image-source: linear-gradient(45deg, #f06, #ff0, #0f6);
    border-image-slice: 1;
  }
  25% {
    border-image-source: linear-gradient(45deg, #0f6, #f06, #ff0);
    border-image-slice: 2;
  }
  50% {
    border-image-source: linear-gradient(45deg, #ff0, #0f6, #f06);
    border-image-slice: 3;
  }
  75% {
    border-image-source: linear-gradient(45deg, #f06, #ff0, #0f6);
    border-image-slice: 4;
  }
  100% {
    border-image-source: linear-gradient(45deg, #0f6, #f06, #ff0);
    border-image-slice: 5;
  }
}

/* Apply the animation to your .boxStyle */
.boxStyle {
  background-color: #fff; /* White background */
  width: 300px;
  height: 100vh;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  position: fixed;
  z-index: 3;
  top: 0;
  transform: translateX(-300px); /* Initially off-screen */
  transition: all 0.5s ease-in-out; /* Add transition for smooth animation */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
  
  /* Apply the disco lights animation to the border */
  border: 5px solid transparent;
  border-image: linear-gradient(45deg, #f06, #ff0, #0f6);
  border-image-slice: 1;
  animation: discoLights 3s infinite linear;
}

/* Style for open aside */
.boxStyle.open {
  transform: translateX(0); /* Move box to the right */
}

/* Style for close aside */
.boxStyle.close {
  transform: translateX(-300px); /* Move box to the left */
  width: 0;
  padding: 0;
}

/* Scrollbar Styles */
.boxStyle::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.boxStyle::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.boxStyle::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
}

.boxStyle::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}







@media screen and (max-width: 767px) {
  /* mobile screen */
  .card {
    padding: 0.5rem;
  }
  .header img{
    height:20px;
  }
  .header .image{
    height:30px; 
  }

  .card h1 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .card p {
    font-size: 1rem;
  }
  
  /* hide home and sett components on mobile */
  .col-1 {
    display: none;
  }
}
