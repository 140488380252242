  
  .active {
    background-color: black;
    color: #fff;
    font-weight: bold;
    border-radius: 7px;
    font-size: 10px;
  }
  

  .nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: inherit;
    color: inherit;
    border-radius: inherit;
    
  }
  .nav-link:hover{
      color: greenyellow;
  }
  
  .nav-link img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: inherit;
    color: inherit;
  }
  
  .nav-link span {
    font-weight: bold;
    background-color: inherit;
    color: inherit;
  }
  
  

 /* styles.css */

/* Add other styles here */

/* Define the disco light animation */
@keyframes disco {
  0% {
    background-color: #ff0000; /* Red */
  }
  25% {
    background-color: #00ff00; /* Green */
  }
  50% {
    background-color: #0000ff; /* Blue */
  }
  75% {
    background-color: #ffff00; /* Yellow */
  }
  100% {
    background-color: #ff00ff; /* Magenta */
  }
}

/* Apply the animation to the .sub-header */
.sub-header {
  position: fixed;
  overflow-x: scroll;
  width: 100%;
  padding: 10px;
  font-size: 10px;
  background-color: white;
  top: 50px;
  z-index: 1;
  /* animation: disco 5s linear infinite; Use the disco animation for continuous color change */
  
  /* Create a 3D border effect for the bottom border */
  /* border-bottom: 2px solid ;  */
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  
  
 
}

  @keyframes disco {
    0% {
      border-color: #ff0000; /* Red */
    }
    25% {
      border-color: #00ff00; /* Green */
    }
    50% {
      border-color: #0000ff; /* Blue */
    }
    75% {
      border-color: #ffff00; /* Yellow */
    }
    100% {
      border-color: #ff00ff; /* Magenta */
    }
  }
  .sub-header::-webkit-scrollbar {
    height: 0px; /* width of the scrollbar */
  }
  .sub-header::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }
  
  .sub-header::-webkit-scrollbar-thumb {
    background: #888; /* color of the thumb */
  }
  
  .sub-header::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb when hovered */
  }

  @media (max-width: 768px) {
    .sub-header{
      padding: 0px;
      

    }
  }