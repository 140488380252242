/* Style for Header */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  header .logo{
    height: 40px;
    width: auto;
  }
  header .logo img{
    height: 30px;
    width: 100%;
    
  }
  
  header img {
    height: 50px;
    width: auto;
  }
  
  nav ul {
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    margin-right: 20px;
    border-radius: 50%;
    border:"2px solid red"
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    
  }
  i
  
  /* Style for Hero Section */
  .hero {
    /* height: 450px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  
  .hero img {
    /* height: 100%;
    width: 100%; */
  object-fit: cover;
  background-size: cover; 
  background-repeat: no-repeat; 
  background-color: #f0f0f0; /* Set your desired background color */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflowing content on mobile */
  /* padding: 20px; */
  box-sizing: border-box;
  }
  
  /* Style for Products Section */
  .products {
    padding: 50px;
  }
  
  .products h2 {
    margin-bottom: 20px;
  }
  
  .products ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .products li {
    margin: 20px;
    width: 300px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .products li img {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
  }
  
  .products li h3 {
    margin: 10px 0;
  }
  
  .products li p {
    font-size: 14px;
    color: #c5c3c3;
  }
  .FAQs {
    padding: 10px;
    background-color: #f2f2f2;
    font-size: 16px;
  }
  
  /* Style for Contact Section */
  .contact {
    padding: 50px;
    background-color: #f2f2f2;
  }
  
  .contact h2 {
    margin-bottom: 20px;
  }
  
  .contact form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .contact form input,
  .contact form textarea {
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .contact form button[type="submit"] {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
  }
  
  .contact form button[type="submit"]:hover {
    cursor: pointer;
    background-color: #666;
  }
  
  /* Style for Footer */
  footer {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  footer a {
    color: #333;
    margin: 0 10px;
  }
  
  @media screen and (max-width: 768px) {
    /* Responsive Style for Header */
    header {
      flex-wrap: wrap;
    }
  
    header img {
      margin-bottom: 20px;
    }
  
    nav ul {
      margin-top: 20px;
    }
  
    nav ul li {
      margin-right: 10px;
    }
  
    /* Responsive Style for Products Section */
    .products li {
      max-width: 100%;
    }
  
    /* Responsive Style for Contact Section */
    .contact form input,
    .contact form textarea {
      width: calc(100% - 20% )
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    max-width: 100%;
    height: auto;
  }
  
  nav ul {
    display: flex;
    list-style: none;
  }
  
  nav li {
    margin-left: 1rem;
  }
  
  nav a {
    text-decoration: none;
    color: #333;
    border-bottom: 2px solid transparent;
    transition: border-color 0.2s ease-in-out;
  }
  
  nav a:hover {
    border-color: #333;
  }
  
/* ProductCard.css */


/* Basic styles for the product card */
.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  max-width: 300px; /* Adjust this for responsiveness */
}

.product-card:hover {
  transform: translateY(-5px);
}

/* Styles for the product image */
.product-card img {
  width: 100%;
  height: auto;
}

/* Styles for product details */
.product-details {
  padding: 16px;
}

.product-details h2 {
  font-size: 1.25rem;
  margin: 0;
  color: #333;
}

.product-details .price {
  font-weight: bold;
  color: #e91e63;
  margin: 8px 0;
  text-align: left;
}

.product-details .rating {
  color: #888;
  margin: 8px 0;
}

/* Styles for the favorite heart icon */
.favorite {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #ccc;
}

.favorite.favorited {
  color: #e91e63;
  animation: heartbeat 1s infinite;
}

/* Styles for the Add to Cart button */
.add-to-cart {
  background-color: #e91e63;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}

/* Responsive CSS */
@media (max-width: 768px) {
  .product-card {
    max-width: 100%;
  }
}

