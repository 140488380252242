/* .payment-checkout-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .payment-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .payment-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
   */
  .cart-product-display{
     max-height: 350px;
     /* border: 1px solid gray; */
     padding: 10px;
     border-radius: 7px;
     overflow-y: scroll;
  }

  .cart-product-display::-webkit-scrollbar {
    width: 5px; /* width of the scrollbar */

  }
  .cart-product-display::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }
  
  .cart-product-display::-webkit-scrollbar-thumb {
    background: #888; /* color of the thumb */
  }
  
  .cart-product-display::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb when hovered */
  }