.dashboard .card {
    transition: transform 0.3s ease-in-out; /* Add smooth transition for the transform property */
  }
  
  /* Apply the hover effect to expand the card */
  .dashboard .card:hover {
    transform: scale(1.05); /* Scale up the card size slightly */
    font-weight: bolder;
    /* color: #0f6; */
    /* -webkit-text-fill-color: transparent; */
    /* text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); */
  }