.footer{
    text-align: center;
    padding-top: 20px;
    background-color: #fff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    /* position: fixed; */
    position: relative;
    top:auto;
    bottom: 0px;
    right:0px;
    left: 0px;
    height:60px;
    width: 100%;
    margin-top: auto;
}

  
  .footer a {
    color: #333;
    margin: 0 10px;
  }