/* Example CSS for ProfileCard */
.profile-card {
    width: 330px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #b0cde6;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
  }
  

  
  .profile-photo-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* overflow: hidden; */
    margin-bottom: 10px;
    background-color: #ffffff;
    position: relative; /* Add position relative for containing the edit icon */
  }
  
  .profile-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 8px; /* Add margin for spacing */
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    font-weight: bold; /* Add bold font weight */
    font-size: 24px; /* Increase font size */
    color: #ffffff; /* Set visible color for the icon */
    background-color: rgba(0, 0, 0, 0.5); /* Add transparent background */
    border-radius: 50%; /* Make the icon a perfect circle */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-name {
    font-size: 1.4rem;
    margin-top: auto;
    color: #2e4053;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .profile-contact {
    font-size: 1rem;
    color: #34495e;
    font-family: 'Helvetica', sans-serif;
    text-align: center;
  }
  

  /* App.css (or any CSS file you are using) */

.image-uploader {
  text-align: center;
  margin-top: 20px;
}
.image-uploader  .upload-profile-image{
  cursor: pointer;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 7px;
}

 .image-uploader input[type="file"] {
  display: none;
}

.profileimage-preview {
  margin-top: 10px;
  max-width: 100px;
  max-height:512px;
  border-radius: 50%;
}

.image-preview-container{
  margin-top: 10px;
  max-width: 80px;
  max-height:80px;
  border-radius: 50%;
  border: 2px solid gray;
  
}

/* App.css (or any CSS file you are using) */

/* ... Other CSS rules ... */

.crop-container {
  /* position: relative; */
  z-index: 2;
}





/* Styles for the buttons */
.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom as needed */
  width: 100%;
}

.ok-button,
.cancel-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
}

.ok-button:hover,
.cancel-button:hover {
  background-color: #444;
}


.cropcontainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 80vh;
}

.crop-section {
  text-align: center;
  width:200px;
  height: 200px;
  /* margin-top: 20px; */
}

.scale-input {
  /* margin-top: 20px; */
}

.button-group {
  /* margin-top: 20px; */
}

/* Add responsive styles as needed */


/* ... Other CSS rules ... */
