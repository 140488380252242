/* CSS for the multiple step form */

.form-card {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
  }
  
  .btn {
    height: 40px;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btn-dark {
    background-color: #000;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #ccc;
    color: #000;
  }
  
  /* Media queries for responsiveness */
  
  @media (max-width: 768px) {
    .form-card {
      padding: 20px;
    }
  
    .btn {
      margin-bottom: 10px;
    }
  }
  