.orders-table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orders-table th,
  .orders-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .order-image img {
    max-width: 50px;
    height: auto;
  }
  
  .action-buttons button {
    padding: 6px 10px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #f44336;
  }
  
  .return-button {
    background-color: #2196f3;
  }
  
  @media (max-width: 768px) {
    .orders-table th,
    .orders-table td {
      padding: 8px 10px;
     }
  }

  /* OrderTable.css */

/* ... (your existing CSS) */

/* OrderTable.css */

/* ... Your existing CSS styles */

/* 3D Card Effect */
tbody {
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: transform 0.5s ease-in-out;
  margin: 0 20px; /* Add 20px margin to the left and right */
  transform: rotateX(15deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

tbody:hover {
  transform: perspective(800px) rotateX(0deg);
}


/* 3D card effect for tbody */
/* .tbody-3d-card {
 
} */

/* Hover effect for the tbody */
/* .tbody-3d-card:hover {
 transform: perspective(800px) rotateX(0deg);
} */

  