/* Login Page */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
    background-color: #f0f0f0;
    
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    font-size: 14px;
  }
  
  .login-form__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .login-form__logo img {
    width: 200px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .error{
    text-align: center;
    color:red;
    font-weight: bolder;
  }
  
  label {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .input-field {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
  
  .errorindicator{
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid red;
    border-radius: 3px;
 }
 
  .login-btn {
    padding: 10px;
    background-color: black;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .signup-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .signup-link a {
    color: #007bff;
  }
  
  /* Signup Page */
  
  .signup-container {
    margin-top: 50px ;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    font-size: 14px;
  }
  
  .signup-form__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .signup-form__logo img {
    width: 200px;
  }
  
  .signup-form h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .signup-form label {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .signup-form .input-field {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
  .signup-form .errorindicator{
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid red;
    border-radius: 3px;
 }


  
  .signup-btn {
    padding: 10px;
    background-color: black;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .login-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-link a {
    color: #007bff;
  }
  
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button:active {
    background-color: #004599;
  }
  
  .button:focus {
    outline: none;
  }

  button {
    display: block;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
   
  }
  
  button:active {
    background-color: #004599;
   
  }

  button:focus {
    outline: none;
  }
    
  p {
    text-align: center;
    font-size: 14px;
  }
  
  