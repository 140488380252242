.shop-card card{
  transition: transform 0.3s ease-in-out;
}
.shop-card .card:hover {
  transform: scale(1.05); /* Scale up the card size slightly */
}
.gradient-bg {
    background: linear-gradient(135deg, #ff00cc, #0066ff);
    /* Replace the above color codes with your desired gradient colors */
    height: 100vh;
    /* Set the height as per your requirements */
    perspective: 1000px;
    /* Set the perspective value as per your desired 3D effect */
  }
  
  .gradient-bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    /* Replace the above color code with your desired color for the 3D effect */
    transform-origin: bottom center;
    transform: rotateX(60deg);
    /* Adjust the rotateX value to control the tilt angle of the 3D effect */
  }
  

  main{
    position: relative;
    margin-top: 100px;
    min-height: 76.8vh;
    padding-top: 10px;
    padding-bottom: 50px;
    overflow-x: hidden;
  }

/* Classic Design */
.search-container {
  display: flex;
  /* position:fixed; */
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  /* z-index: 1; */
}

.search-input {
  border: none;
  background-color: transparent;
  outline: none;
  /* width: 50%; */
  flex: 1;
  padding: 5px;
  font-size: 16px;
}

.search-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #fff;
  border-radius: 5px;
  font-size: 8px;
  /* padding: 10px; */
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-container {
    flex-direction: row;
    align-items: stretch;
  }
  
  .search-input {
    margin-top: 5px;
   /* width: 80%; */
  }
  
  .search-button {
    margin-top: 5px;
    /* width: 20%; */
  }
}

/* Product details Pages Comapany details display  part styleing start */
.logo-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border: 2px solid rgb(221, 219, 219);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
}


.logo {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-right: 10px; /* Adjust the spacing as needed */
  cursor: pointer;
  border-radius: 50%;
}

.company-name {
  font-family: Arial, sans-serif; /* Choose a classic font */
  font-size: 20px; /* Adjust the size as needed */
  cursor: pointer;
}
/* Product details Pages Comapany details display part styling End */


/* Product details Pages imgae and video part styleing start */
.Prodcut-img-vid-container  {
  margin: 20px;
  width:300px;
  height: 300px;
  /* background-color: #00000082; */
  border-radius: 7px;
}

.Prodcut-img-vid-container  img , .Prodcut-img-vid-container  video{
  width:300px;
  height: 300px;

}

.display-container {
  position: relative;
}

.zoom-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  /* padding: 10px; */
}
.mini-display{
  background-color: #d6d2d242;
  border-radius: 5px;
}

.zoom-icon .bi-arrows-fullscreen{
  color: white;
  background-color: rgba(0, 0, 0, 0.488);
  font-weight: bolder;
  font-size: 15px;
  padding: 10px;
}
.zoom-icon .bi-arrows-fullscreen:hover{
  color: white;
  background-color: rgba(0, 0, 0, 0.819);
  font-weight: bolder;
  font-size: 15px;
  padding: 10px;
}
.zoom-icon .bi-arrows-fullscreen:active{
  color: white;
  background-color: rgb(0, 0, 0);
  font-weight: bolder;
  font-size: 15px;
  padding: 10px;
}

.navigation-button {
  position: absolute;
  top: 50%;
  background-color: #93929288;
  color: #fff;
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.modal-img-vid-back{
  position: absolute;
  top: 10px;
  left: 10px; 
  background-color: #93929288;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}
.modal-img-vid-back .bi-arrow-left {
  font-size: 30px;
  
}

.navigation-button:hover {
  background-color: #aaa;
}

.navigation-button.left {
  left: 0;
}

.navigation-button.right {
  right: 0;
}

.display-container-modal{
  top:0px;
  max-width: 750px;
  position: relative;
  height: 99.5vh;
  margin: auto;
}
.display-container-modal video , .display-container-modal img {
  top:0px;
  left: 0px;
  position: absolute;
   width: 100%;
   height: 100%;
   /* z-index: 0; */
   /* padding: 20px 0px 40px 0px; */
   /* margin-bottom: 20px; */
}

/* Product details Pages imgae and video part styling end */


/* Product details Pages product part styling start */
/* Classic Design */

.product-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-container div {
  margin-bottom: 10px;
  font-size: 16px;
}

.product-container div:last-child {
  margin-bottom: 0;
}

.product-container .product-name {
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}


.product-container .quantity {
  color: #555;
  font-weight: bolder;
  font-size: 14px;
}
.product-container .show-more-btn {
 padding: 2px 5px;
 border: 2px solid gray;
 background-color: rgb(10, 10, 10);
 color:white;
 border-radius: 7px;
 cursor: pointer;
}
.product-container .show-less-btn {
  padding: 2px 5px;
 border: 2px solid gray;
 background-color: rgb(84, 81, 81);
 color:white;
 border-radius: 7px;
 cursor: pointer;
 
}

.product-container .price {
  color: #777;
  /* font-size: 20px; */
}

.product-container .star-rating {
  color: #ffc107;
  font-size: 20px;
}

.product-container button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-container button:hover {
  background-color: #45a049;
}

/* Responsive Design */

/* Adjust padding and margin for smaller screens */
@media (max-width: 767px) {
  .product-container {
    padding: 10px;
    margin-bottom: 10px;
  }
}

/* Adjust font size for smaller screens */
@media (max-width: 480px) {
  .product-container div {
    font-size: 14px;
  }
  
  .product-container .star-rating {
    font-size: 16px;
  }
  
  .product-container button {
    font-size: 14px;
  }
}

/* Additional Styles */

.product-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-container .product-name {
  font-size: 20px;
}

.product-container .quantity::before,
.product-container .price::before {
  font-weight: bold;
}

.product-container span {
  font-weight: normal;
}

.product-container .span-highlight {
  font-weight: bold;
  color: #ff5722;
  font-size: 14px;
}

/* Product details Pages product part styleing end */

/* Product Reviews style start */
.product-review-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: black;
  font-size: 24px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.reviews-list {
  margin-top: 20px;
  margin: 20px;
}

.review {
  border-bottom: 1px solid #ddd;
  padding: 10px 0px 10px 20px;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.reviewer-details {
  display: flex;
  align-items: center;
}

.reviewer-name {
  font-weight: bold;
  color: black;
  margin-right: 10px;
}

.rating {
  color: yellow;
  position: relative;
}

.review-comment {
  color: black;
  text-align: left;
}


/* Styling for Bootstrap icons  rating star */
.bi-star-fill, .bi-star-half, .bi-star {
  font-size: 16px;
  color: gold;
}

.star-rating .star.selected {
  color: gold;
  
}
/* Reivew modal */
.AddReviewModal .modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 9999;
 
  
}

.AddReviewModal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 778px) {
  .AddReviewModal .modal-content {
    margin-left: 20%;
    margin-right: 20%;
  }
}


.AddReviewModal .modal-content h3 {
  margin-top: 0;
}

.AddReviewModal .modal-content button {
  margin-top: 10px;
}




/* Product Reviews style end */

/* Define the vibrate animation */
@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px) rotate(-2deg); }
  50% { transform: translateX(2px) rotate(2deg); }
  75% { transform: translateX(-2px) rotate(-2deg); }
  100% { transform: translateX(2px) rotate(2deg); }
}

/* Error part */
/* Apply the vibrate animation to the element */
.vibrate {
  animation: vibrate 1s linear 1;
}

/* Style the error text */
.error {
  color: red;
  font-weight: bold;
  font-size: 15px;
}

/* tic */
/* Add this CSS to your component or a CSS file */

.tick {
  font-size: 12px;
  cursor: pointer;
}

.tick-animated {
  animation: tickAnimation 0.5s ease-in-out;
}

@keyframes tickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



