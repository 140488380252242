.banner{
    border-radius: 20px;
    padding: 20px;
    height:auto; 
    width:100%;
}
@media screen and (min-width:900px) {
  .banner{
    height:300px;
  }
}
.business-name-card {
    /* Classic design properties */
    background-color: #F0F0F0;
    border: 2px solid #333;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #888;
    padding: 10px;
    width: 300px;
    height: 100px;
    
    /* Positioning properties */
    position: relative;
    top: 5%;
    left: 70px;
    
    /* Typography properties */
    font-size: 12px;
    font-weight: lighter;
    font-family: fantasy;
    text-align: center;
  }


  /* Styles for the CreateCompanyForm */
.createcompany {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  overflow-y: scroll;
  font-size: 16px;
  font-weight: bolder;
}

.createcompany .text-center {
  margin-bottom: 10px;
}

.createcompany button {
  font-size: 20px;
  color: white;
  background-color: #343a40;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.createcompany form {
  margin-top: 20px;
}

.createcompany .form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.createcompany .form-group {
  margin-bottom: 15px;
}

.createcompany label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.createcompany input[type="text"],
.createcompany textarea,
.createcompany select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.createcompany select {
  appearance: none;
  padding-right: 30px;
  /* background: url('arrow-down.png') no-repeat right center; */
  background-size: 20px 20px;
}

.createcompany .country-select {
  padding-right: 40px;
}

.createcompany .photo-preview img {
  max-width: 200px;
  height: auto;
  margin-top: 10px;
}

.createcompany button[type="submit"] {
  background-color: #343a40;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.createcompany button[type="submit"]:hover {
  background-color: #292d31;
}

/* .photo-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 20px;
}

.photo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

  

  /* Create Bussiness component styling part  */
  /* .createcompany .form-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding-top: 20px;
  }
  
  .createcompany .form-group {
    margin-bottom: 2px;
  }
  
  .createcompany label {
    font-weight: bold;
  }
  
  .createcompany input[type='text'],
  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  

  .createcompany .country-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333333' viewBox='0 0 24 24'%3E%3Cpath d='M12 18.28l-6.364-5.772a1 1 0 0 1 .272-1.712 1 1 0 0 1 1.104.217L12 15.043l4.988-4.51a1 1 0 0 1 1.104-.216 1 1 0 0 1 .272 1.712L12 18.28z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 16px;
  }
  
  .country-select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  } */
  

  /* Create Product card style */
  .create-product {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 9998;
    color: white;
    text-align: left;
  }
  
  .overlay {
    position: fixed;
    /* padding-top:150px ; */
    width: 100%;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9998;
  }
  
  
  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    z-index: 1;
  }
  
  /* Rest of the CSS code */
  
  
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-step {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="text"],select ,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    }
  .button-row {
    display: flex;
    position: absolute;
    bottom: 35px;
    right: 20px;
    justify-content: space-between;
    /* margin-top: 20px; */
    flex-direction: row;

  }
  
  .button-row button {
    flex: 1;
    margin: 0 5px;
  }

  .button-row .disabledbutton{
    background-color: black;
    color: gray;
    cursor:default;
  }

  .preview-item {
    position: relative;
  }
  
  .delete-button {
    position: absolute;
    top:5px ;
    right: 5px;
    background-color: black;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    /* z-index: 1; */
  }
  
  
  @media (max-width: 768px) {
    .button-row {
      flex-direction: row;
      align-items: stretch;
    }
  
    .button-row button {
      margin: 5px 0;
    }
    .preview-video {
      width: 250px;
      height: auto;
      border-radius: 20px;
    }
  }
 

  
  button[type="submit"] {
    background-color: #007bff;
  }
  
  button[type="button"] {
    background-color: white;
    margin-right: 10px;
    color:black
  }
  
  @media (min-width: 576px) {
    .create-product {
      width: 80%;
      max-width: 600px;
    }
    
  }
  
  @media (min-width: 768px) {
    .create-product {
      width: 60%;
      max-width: 800px;
      
    }
    
  }
  
  @media (min-width: 992px) {
    .create-product {
      width: 50%;
      max-width: 1000px;
    }
  }

  /* Step Indicator */
  .step-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
  }
  
  .step {
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
    background-color: #f2f2f2;
    position: relative;
  }
  
  
  
  .step.active {
    background-color: green;
    color: #fff;
  }

  .preview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
  #product-images {
    display: none;
  }
  
  #product-images:focus {
    outline: none;
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.6);
  }

  .upload-images{
    width: 100%;
    text-align: center;
    border: 2px solid white;
    padding:20px 10px 20px 10px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  .video-preview {
    align-content: center;
  }
  
  .preview-video {
    width: 400px;
    height: auto;
    border-radius: 20px;
  }

  
    
  
  
  