/* Tabs Container */
.tabs-container {
    display: flex;
    flex-direction: column;
    min-width: 400px; /* Adjust the max-width as needed */
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Tabs Row */
  .tabs-row {
    display: flex;
    background-color: #f0f0f0;
  }
  
  /* Individual Tab */
  .tab {
    flex: 1;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-bottom-color 0.2s;
  }
  
  .tab:hover {
    background-color: #e0e0e0;
  }
  
  /* Active Tab */
  .active-tab {
    background-color: #007bff;
    color: #fff;
    border-bottom-color: #007bff;
  }
  
  /* Tab Content */
  .tab-content {
    padding: 20px;
  }
  
  /* Content for Each Tab */
  .tab-content > div {
    display: none;
  }
  
  .tab-content > div.active-tab-content {
    display: block;
  }

  #otp-input-container {
    display: flex;
    justify-content: space-between;
    width: 200px; /* Adjust the width as needed */
    margin-top: 10px;
  }
  
  #otp-input-container input {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
    outline: none;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  